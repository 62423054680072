'use client';

import { Box, Button, Link, SxProps } from '@mui/material';
import FacebookIcon from 'public/assets/facebook-icon.svg';
import GoogleIcon from 'public/assets/google-icon.svg';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { baseUrl } from '~config/base-url';
import useResponsive from '~hooks/useResponsive';
import { getReferralId } from '~services/tagging';
import { useRouter } from 'next/router';
import { setSavedPath } from '~screens/auth/store/authSlice';
import { useModalDialogs } from '~screens/shared/modal-dialogs/modal-dialogs-provider';
const buttonStyles: SxProps = {
  border: '1px solid grey',
  color: 'black',
  display: 'flex',
  gap: '8px',
  width: '100%',
  '&:hover': {
    backgroundColor: 'transparent',
    border: '1px solid grey'
  }
};
const SocialButtons = () => {
  const isMobile = useResponsive('down', 'sm');
  const dispatch = useDispatch();
  const [referialId, setReferialId] = useState(() => getReferralId());
  const {
    user,
    savedPath
  } = useSelector((state: any) => state.auth);
  const {
    overrideRouterRedirectUrl
  } = useModalDialogs();
  const order_all_submits = useSelector((state: any) => state.order);
  const {
    step1FormData: {
      _id: orderId1
    },
    step2FormData: {
      _id: orderId2
    },
    step3FormData: {
      orderId: orderId3
    },
    step4FormData: {
      orderId: orderId4
    }
  } = order_all_submits || {};
  const orderId = orderId4 || orderId3 || orderId2 || orderId1 || undefined;
  let userId = user?.userDto?._id;
  const {
    asPath
  } = useRouter();
  // console.log({ asPath })

  const state = useMemo(() => encodeURIComponent(JSON.stringify({
    roleName: 'Client',
    referralUserId: referialId,
    userId: userId || undefined,
    orderId,
    redirectUrl: (overrideRouterRedirectUrl ? overrideRouterRedirectUrl : asPath) || '/'
  })), [referialId, userId, orderId, overrideRouterRedirectUrl, asPath]);
  useEffect(() => {
    if (overrideRouterRedirectUrl) dispatch(setSavedPath(overrideRouterRedirectUrl ? overrideRouterRedirectUrl : asPath));else dispatch(setSavedPath(asPath));
  }, [asPath, overrideRouterRedirectUrl]);
  const handleGoogleLogin = () => {
    window.location.href = `${baseUrl}/api/social-auth/google/login?state=${state}`;
  };
  const handleFacebookLogin = () => {
    window.location.href = `${baseUrl}/api/social-auth/facebook/login?state=${state}`;
  };
  return <Box sx={{
    display: isMobile ? 'block' : 'flex',
    gap: isMobile ? 0 : '12px',
    width: '100%'
  }} data-sentry-element="Box" data-sentry-component="SocialButtons" data-sentry-source-file="SocialButtons.tsx">
      <Button variant="outlined" sx={{
      ...buttonStyles,
      mb: isMobile ? 2 : 0
    }} onClick={handleFacebookLogin} size="large" data-sentry-element="Button" data-sentry-source-file="SocialButtons.tsx">
        <FacebookIcon data-sentry-element="FacebookIcon" data-sentry-source-file="SocialButtons.tsx" />
        Facebook
      </Button>
      <Button variant="outlined" sx={{
      ...buttonStyles,
      mb: isMobile ? 2 : 0
    }} onClick={handleGoogleLogin} size="large" data-sentry-element="Button" data-sentry-source-file="SocialButtons.tsx">
        <GoogleIcon data-sentry-element="GoogleIcon" data-sentry-source-file="SocialButtons.tsx" />
        Google
      </Button>
      {/* <Button variant="outlined" sx={buttonStyles} size="large">
        <AppleIcon />
        Apple
       </Button> */}
    </Box>;
};
export default SocialButtons;