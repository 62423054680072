import { forwardRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Box } from '@mui/material';
import getRatio from './getRatio';
import { ImageProps } from './types';
import { IMAGES_PREFIX } from '~config/config-global';
const Image = forwardRef<HTMLSpanElement, ImageProps>(({
  ratio,
  disabledEffect = false,
  effect = 'blur',
  sx,
  imageStyles,
  ...other
}, ref) => {
  const content = <Box component={LazyLoadImage} wrapperClassName="wrapper" effect={disabledEffect ? undefined : effect} placeholderSrc={disabledEffect ? IMAGES_PREFIX + '/assets/transparent.png' : IMAGES_PREFIX + '/assets/placeholder.svg'} sx={{
    width: 1,
    height: 1,
    objectFit: 'contain',
    ...imageStyles
  }} {...other} />;
  if (ratio) {
    return <Box ref={ref} component="span" sx={{
      width: 1,
      lineHeight: 1,
      display: 'block',
      overflow: 'hidden',
      position: 'relative',
      pt: getRatio(ratio),
      '& .wrapper': {
        top: 0,
        left: 0,
        width: 1,
        height: 1,
        position: 'absolute',
        backgroundSize: 'cover !important'
      },
      ...sx
    }}>
          {content}
        </Box>;
  }
  return <Box ref={ref} component="span" sx={{
    lineHeight: 1,
    display: 'block',
    overflow: 'hidden',
    position: 'relative',
    '& .wrapper': {
      width: 1,
      height: 1,
      backgroundSize: 'cover !important'
    },
    ...sx
  }}>
        {content}
      </Box>;
});
export default Image;