import valid from 'card-validator';
import {
  object,
  array,
  boolean,
  date,
  mixed,
  number as yupNumber,
  ref,
  string,
} from 'yup';
import { emailRx } from './constants/regex';
import { isFutureDate } from './helper';

const boolean_validator = (label: string) =>
  boolean().required().label(`* ${label}`);
const string_validator = (label: string) =>
  string().required(`${label} is required`);
const other_validator = ({ label, field }: any) => {
  return string().when(field, {
    is: (field: string) => {
      return field == '-1';
    },
    then: () => string().required().label(label),
  });
};
const details_validator = ({ min, max, label, isRequired = true }: any) => {
  return string()
    .min(min, `${label} must be at least ${min} characters`)
    .max(max, `${label} must be less than ${max} characters`)
    .when('dummy', {
      is: () => isRequired,
      then: () => string().required(`${label} is required`),
      otherwise: () => string().notRequired(),
    });
};
const order_topics_validator = () => {
  return (
    array()
      .of(
        object({
          topicId: string().required('Topic ID is required'),
          name: string().required('Topic Name is required'),
          details: string()
            // .min(0, 'Topic Text must be at least 10 characters long')
            .max(450, 'Topic Text must be no longer than 450 characters'),
        })
      )
      .min(3, 'At least 3 topics are required')
      // .test(
      //     'orderTopicDtos',
      //     'Please enter details for 4 to 6 topics',
      //     (inputValue: any, context) => {
      //         if (inputValue?.length > 6) {
      //             return context.createError({
      //                 message:
      //                     `Please enter no more than 6 topics, delete ` +
      //                     (inputValue.length - 6),
      //                 path: 'orderTopicDtos',
      //             });
      //         }
      //         return true;
      //     }
      // )
      .required('Topics are required')
  );
};
const essential_topics_validator = () => {
  return mixed().test('orderTopicDtos', '', function (value: any) {
    if (value.filter((obj: any) => obj?.isEssential)?.length == 0)
      return this.createError({
        message: `Please select up to 3 essential topics`,
        path: 'orderTopicDtos',
      });
    else return true;
  });
};
const essential_topics_details_validator = () => {
  return mixed().test('orderTopicDtos', '', function (value: any) {
    // if (value.filter((obj: any) => obj?.essentialDetails)?.length == 0)
    //     return this.createError({
    //         message: 'Please enter details for essential topics',
    //         path: 'orderTopicDtos',
    //     });
    // else if (value.some((obj: any) => obj?.essentialDetails?.length < 10))
    //     return this.createError({
    //         message: `All essential details must be at least 10 characters`,
    //         path: 'orderTopicDtos',
    //     });
    // else
    if (value.some((obj: any) => obj?.essentialDetails?.length > 450))
      return this.createError({
        message: `All essential details must be at less than 450 characters`,
        path: 'orderTopicDtos',
      });
    else return true;
  });
};
const file_validator = (size: number) =>
  mixed().test('fileSize', 'The file is too large', (value: any) => {
    if (!value?.[0]?.size) return true;

    return value[0].size <= size;
  });

const Expiration_date_validator = string()
  .required('Expiration date is required')
  .matches(/^(0[1-9]|1[0-2])\/[0-9]{2}$/, 'Invalid date format. Use MM/YY')
  .test(
    'is-future-date',
    'Expiration date must be in the future',
    isFutureDate
  );
const password_validator = string().required('Password is required');
const confirm_password_validator = string()
  .oneOf([ref('newPassword')], '* Passwords must match')
  .required()
  .label('* Confirm Password');
const otp_validator = string()
  .required('One Time Code is required')
  .min(6, 'Must be exactly 6 digits')
  .max(6, 'Must be exactly 6 digits');
const amount_validator = string().required('Amount is required');
const order_id_validator = string().required('Select a song');
const design_template_validator = string().required('Select a design template');
const email_validator = string()
  .required('email is required')
  .matches(emailRx, 'Enter a valid email');
const confirm_email_validator = string().test(
  '',
  "Check the emails, they aren't matching",
  (value, { parent }) => {
    return value?.toLocaleLowerCase() == parent.email?.toLocaleLowerCase();
  }
);
const future_date_validator = date()
  .required('Date is required')
  .min(new Date(), 'Please choose future date');
const cvv_validator = string().label('CVC').min(3).max(4).required();
const card_number_validator = mixed().test(
  '',
  'Enter a valid card number',
  (value: any) => {
    if (!value) return false;

    let numberValidation = valid.number(value);

    return numberValidation.isPotentiallyValid;
  }
);
const other_amount_validator = string().when('amount', {
  is: (field: string) => {
    return field == '0';
  },
  then: () =>
    yupNumber()
      .integer()
      .min(1, 'Amount should be greater than 1$')
      .required()
      .label('Other amount'),
  otherwise: () => yupNumber().notRequired(),
});

export {
  file_validator,
  other_validator,
  string_validator,
  details_validator,
  boolean_validator,
  order_topics_validator,
  essential_topics_validator,
  essential_topics_details_validator,
  cvv_validator,
  otp_validator,
  email_validator,
  amount_validator,
  order_id_validator,
  password_validator,
  card_number_validator,
  future_date_validator,
  other_amount_validator,
  confirm_email_validator,
  design_template_validator,
  confirm_password_validator,
  Expiration_date_validator,
};
