import { LoadingButton } from '@mui/lab';
import { CircularProgress, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { ButtonType } from '~config/constants/enums/button-type.enum';
type ButtonProps = {
  title: string | ReactNode;
  loading: boolean;
  onClick: any;
  sx?: object;
  disabled?: boolean;
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'small' | 'medium' | 'large';
  buttonType?: ButtonType;
};
function Button({
  sx,
  title,
  loading,
  size,
  onClick,
  buttonType,
  variant,
  disabled = false
}: ButtonProps) {
  const {
    palette: {
      button
    }
  }: any = useTheme();
  let hoverStyles = {
    background: button.hovered,
    boxShadow: button.shadow
  };
  if (buttonType == ButtonType.Secondary) hoverStyles = {
    background: button.secondaryHovered,
    boxShadow: button.secondaryShadow
  };else if (buttonType == ButtonType.Tertiary) hoverStyles = {
    background: button.tertiaryHovered,
    boxShadow: button.tertiaryShadow
  };
  return <LoadingButton loadingIndicator={<CircularProgress color="inherit" sx={{
    color: 'white'
  }} size={32} />} onClick={onClick} disabled={disabled} fullWidth color="inherit" size={size || 'large'} type="submit" variant={variant || 'contained'} loading={loading} sx={{
    bgcolor: 'primary.main',
    color: 'common.white',
    '&:hover': hoverStyles,
    ...sx
  }} data-sentry-element="LoadingButton" data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      <span>{title}</span>
    </LoadingButton>;
}
export default Button;